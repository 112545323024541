/* @font-face {
    font-family: GilorySemiBold;
    src: url(../../assets/fonts/gilroy-semibold.woff2);
}
@font-face {
    font-family: Gilory;
    src: url(../../assets/fonts/gilroy-regular.woff2);
}
@font-face {
    font-family: GiloryMedium;
    src: url(../../assets/fonts/gilroy-medium.woff2);
} */
@font-face {
  font-family: Product Sans;
  src: url("../../assets/fonts/product-sans/ProductSans-Bold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: Product Sans;
  src: url("../../assets/fonts/product-sans/ProductSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

body {
  /* font-family: BebasRegular */
  font-family: Product Sans;
  overflow-x: hidden;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
input {
  outline: none;
}
.pointer {
  cursor: pointer;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky + .content {
  padding-top: 102px;
}
.container {
  padding-left: 60px !important;
  padding-right: 60px !important;
}
button {
  outline: none !important;
  border: none;
}
.form-field {
  border: 0.5px solid #bbbbbb;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 48px;
  padding: 0 15px;
  position: relative;
  background: #fff;
}
.form-field.disabled,
.form-field.disabled input {
  background: #f7f7f7;
}
.form-field label {
  position: absolute;
  bottom: 0px;
  color: #808080;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0;
  transition: all ease 0.3s;
}

.form-field .input,
.form-field .input-2,
.form-field select {
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  transition: all ease 0.3s;
}

.form-field .input::placeholder {
  color: #808080;
}
.form-field .input:focus,
.form-field .input:valid {
  padding-top: 18px;
}
.form-field .input:focus + label,
.form-field .input:valid + label {
  margin-bottom: 10px;
}

.form-field .extra {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #808080;
  cursor: pointer;
}
.form-field.phone {
  padding: 0 5px;
}
.form-field.phone .react-tel-input .form-control {
  padding-left: 65px;
  outline: none;
}
.form-field.phone .react-tel-input div {
  background-position: 0;
}
.form-field.phone .react-tel-input .selected-flag {
  display: flex;
  align-items: center;
}
.form-field.phone .react-tel-input .selected-flag .flag {
  position: relative;
  top: unset;
  margin: 0;
}
.form-field.phone .react-tel-input .selected-flag .arrow {
  left: 45px;
}
.form-field.phone .react-tel-input .flag-dropdown {
  background: transparent;
  border: none;
}
.form-field.phone .react-tel-input .flag {
  width: 32px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: contain;
}

.form-field.phone .react-tel-input .flag.ng {
  background-image: url("../../assets/images/countries-flag/ng-flag.svg");
}

.form-field.phone .react-tel-input .flag.gh {
  background-image: url("../../assets/images/countries-flag/gh-flag.png");
  border-radius: 5px;
}

.form-field.phone .react-tel-input {
  height: 100%;
  width: 100%;
}
.form-field.phone .react-tel-input .country-list .country {
  display: flex;
  align-items: center;
}
.form-field.phone input {
  height: 100%;
  width: 100%;
  border: none;
  outline: none !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #808080;
  letter-spacing: 1px;
}

/* .form-field.phone input:focus{
    outline:none;
} */
.form-field.phone .react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -7px;
  left: 40px;
  width: 15px;
  height: 15px;
  border: none;
  background-image: url("../../assets/images/phone-caret.svg");
  background-repeat: no-repeat;
}
.react-tel-input {
  font-family: Product Sans !important;
}
.submit-button {
  background: #f79521;
  border-radius: 5px;
  height: 48px;
  width: 100%;
  color: #fff;
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.otp-field {
}
.otp-field input {
  width: 62px !important;
  height: 48px;
  border: 0.5px solid #bbbbbb;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
}
.otp-field .otp-container {
  justify-content: space-between;
}

.cursor-pointer {
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
.submit-button.disabled,
.btn-disabled {
  background: #ffcc91 !important;
  cursor: not-allowed !important;
}
.hide-number::-webkit-outer-spin-button,
.hide-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hide-number {
  -moz-appearance: textfield;
}
.rolling {
  width: 20px;
}
.msg.text-danger {
  font-size: 13px;
}

@media (max-width: 1100px) {
  .container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media (max-width: 500px) {
  .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

/* https://xd.adobe.com/view/ccbd96ed-97a5-48f7-886b-4de7edcd50df-99ee/screen/cc95bd60-ccaa-4cbf-a04d-f65239a77647/?fullscreen */
